@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.logo {
  /* padding: 5px; */
  text-align: center;
  color: #fff;
  /* height: 32px; */
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}

.logo img {
  height: 75px;
}

.logo .img_collapse {
  height: 36px;
}

.img_footer {
  height: 75px;
}

.ant-layout-footer {
  padding: 10px 50px;
}

.grey-back {
  background: #ddd;
}

.card {
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
}

.font-weight-bold {
  font-weight: 500 !important;
  font-size: 13px;
  /* font-size: 0.7rem; */
  /* text-transform: uppercase; */
}

.text-primary {
  color: #1266f1;
}

.add_shipp_box {
  height: 120px;
  background: url("../public/image/card-addd.jpg");
  background-repeat: no-repeat;
  background-size: 300px 120px;
}

.card p {
  font-size: 1.2em;
  font-weight: 700;
  color: #5a5c69 !important;
}

.card {
  border: 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 7%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}

.address {
  border: 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 7%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  border-left: 2px solid #5a5c69;
}

.border-left-primary {
  border-left: 4px solid #002140;
}
.text-primary {
  color: #002140;
}

.border-left-secondary {
  border-left: 4px solid #4f138baf;
}

.text-secondary {
  color: #4f138baf;
}

.border-left-success {
  border-left: 4px solid #019b04;
}
.text-success {
  color: #019b04;
}

.border-left-info {
  border-left: 4px solid #1b74c6;
}
.text-info {
  color: #1b74c6;
}

.border-left-warning {
  border-left: 4px solid #e2ca10;
}

.icon_size {
  font-size: 20px;
  color: #dddfeb !important;
}

.ant-menu-title-content {
  font-weight: 600;
}

.submenu {
  margin: 14px 0px;
}

.form-design {
  padding: 20px;
}

.form-design .ant-col {
  padding: 0px 5px;
}

.form-design h4 {
  font-size: 22px;
  font-weight: 500;
}

.form-design-failed {
  padding: 0px;
}

.form-design-failed .ant-col {
  padding: 0px 2px;
}

/*ant btn secondary design*/
.ant-btn-success {
  color: #fff;
  background: #019b04;
  border-color: #019b04;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #019b04;
  border-color: #019b04;
  background: #fff;
}

.ant-btn-secondary {
  color: #fff;
  border-color: #440f7a;
  background: #440f7a;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: #4f138baf;
  border-color: #4f138baf;
  background: #fff;
}
/*ant btn warning design*/
.ant-btn-warning {
  color: #fff;
  border-color: #e2ca10;
  background: #e2ca10;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #e2ca10;
  border-color: #e2ca10;
  background: #fff;
}
.secondary-font-color {
  color: #440f7a;
}
.warning-font-color {
  color: #e2ca10;
}
.ant-form-item-explain-error {
  padding: 2px 0px 8px 0px;
}
.ant-btn-orange {
  color: #fff;
  border-color: #faad14;
  background: #faad14;
}
.ant-btn-orange:hover,
.ant-btn-orange:focus {
  color: #faad14;
  border-color: #faad14;
  background: #fff;
}
.btn-top {
  margin-top: 100px;
}

.ant-btn-dashed-secondary {
  color: #4f138baf;
  border-color: #4f138baf;
  background: #fff;
  border-style: dashed;
}

.my-profile p span {
  padding-left: 10px;
}

.shipping_detail h5 {
  line-height: 1.6rem;
  font-weight: 500;
}

.address {
  margin: 10px;
  border-radius: 10px;
}

.address h4 {
  margin-bottom: 40px;
}

.address p {
  line-height: 0.9rem;
  margin-left: 10px;
  font-size: 14px;
}

.line_item {
  margin-top: 20px;
}

.rowExp {
  font-size: 15px;
  font-weight: 600;
}

.actionButton {
  width: 180px;
}

.add_shipping_btn {
  width: 97.3%;
  height: 102px;
  margin: 10px;
  border-radius: 10px;
  font-size: 19px;
}

.text-center {
  text-align: center;
}

.p5 {
  padding-left: 5px;
  padding-right: 10px;
}
.title {
  margin: 10px 0px;
  font-size: 18px;
  font-weight: 600;
}

.filter {
  margin: 15px 14px 0px 14px;
}

.mapping_detail label {
  font-weight: 600;
  font-size: 1.1em;
  padding-bottom: 10px;
}

.left_greeting {
  padding: 10px;
  /* color: #3b7bda; */
  font-weight: 600;
}

.left_greeting {
  margin-top: 0px;
  margin-left: 20px;
  font-size: 16px;
}

.item_selected:hover {
  color: #000 !important;
}

.show_box {
  display: block;
  cursor: default;
}

.hide_box {
  display: none;
}

.shipping_status {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px;
}
